body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
  "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@import "~bootstrap/dist/css/bootstrap.min.css";

.dropdown.show {
  overflow: inherit !important;
}

.table-responsive,
.table-responsive div:first-of-type {
  overflow: inherit !important;
}

.table-responsive {
  overflow-x: auto !important;
  -webkit-overflow-scrolling: touch !important;
}

.has-error {
  border-color: #dc3545 !important;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns= 'http://www.w3.org/2000/svg' viewBox= '0 0 12 12' width= '12' height= '12' fill= 'none' stroke= '%23dc3545' %3e%3ccircle cx= '6' cy= '6' r= '4.5' /%3e%3cpath stroke-linejoin= 'round' d= 'M5.8 3.6h.4L6 6.5z' /%3e%3ccircle cx= '6' cy= '8.2' r= '.6' fill= '%23dc3545' stroke= 'none' /%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.row-disabled [role="cell"]:not(:last-of-type) {
  opacity: 0.3;
}

.toasts-container {
  z-index: 9999;
  top: 80px !important;
  right: 15px !important;
  position: fixed !important;
}

.setting-icon {
  font-size: 48px;
}

.side-box--overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0);
  z-index: 99999;
  transition: background-color 300ms ease-in-out;

  &.enter-animation {
    background-color: rgba(0, 0, 0, 0.3);
  }

  &.closing {
    background-color: rgba(0, 0, 0, 0);
  }
}

.side-box--content {
  position: fixed;
  top: 0;
  right: 0;
  max-width: 600px !important;
  width: 40vw !important;
  height: 100vh;
  transform: translateX(100%);
  background-color: #fff;
  z-index: 999999;
  transition: all 300ms ease-in-out;
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 50%);
  padding: 50px 15px;
  overflow-y: auto;

  &.enter-animation {
    transform: translateX(0);
  }

  &.closing {
    transform: translateX(100%);
  }

  .side-box--close {
    position: absolute;
    top: calc(15px - 50px);
    right: 0;
    font-size: 30px;

    &:hover {
      cursor: pointer;
    }
  }
}

.note--footer {
  font-size: 11px;
  margin-top: 10px;
}

.table-responsive div.progress {
  width: 270px;
  display: block;
  overflow: hidden !important;
}

.table-responsive div.progress div {
  height: 100%;
}

.hoverable:hover {
  cursor: pointer;
}

.text-break {
  //white-space: initial;
}

.flex-1 {
  flex: 1 1 100%;
}

.btn-loader {
  height: 25px;
  width: 25px;
  margin-right: 15px;
}

.img-thumbnail {
  width: 90px;
}

.form-check {
  margin-bottom: 0 !important;
  min-height: 17px !important;
}

.form-check-with-overlay {
  position: relative;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
  }
}

.rdt_Table {
  min-height: 300px;
}

.form-hidden {
  display: none;
  visibility: hidden;
}

.car-details-view {
  .form-label {
    font-weight: bold;
  }

  .form-switch {
    position: relative;

    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 99;
    }
  }
}

.form-control {
  &.input-date {
    width: 120px;
  }
}

.pr-label {
  padding-right: 5px;
  font-size: 14px
}

.react-datepicker-wrapper {
  width: auto;
  margin-right: 5px;
}

.cars-search-input {
  min-width: 250px;
}

.toast-container.position-absolute {
  position: fixed !important;
}

.text-no-wrap {
  white-space: nowrap;
}

.ml-small {
  margin-left: 8px;
}

.app-search {
  max-width: unset !important;
}

.card.shadow {
  border: none;
}

.card.disabled {
  opacity: 0.5;
  background: #eee;
}

.card-footer {
  z-index: 99;
}

.card--lighter {
  background-color: #fbfbfb !important;
}

.card--darker {
  background-color: #f5f9fb !important;
}

.account-position {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 130px;
  white-space: nowrap;
}

.modal-huge {
  min-width: 80vw;
}

.modal-medium {
  min-width: 50vw;
}

select.form-control.select-with-arrow {
  padding-right: 40px;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) center;
}

.flex-1 {
  flex: 1;
}

.driver-select-holder {
  width: 190px;
}

.status-preview {
  padding: 5px 8px;
  border-radius: 5px;
}

.order-number-value {
  white-space: normal;

  &.order-number-value-hidden {
    display: none;
    visibility: hidden;
  }
}

.filter-col {
  margin-right: 10px;
}

.rbt-close .rbt-close-content {
  opacity: 0;
}

.delivery-badge {
  padding: 5px 8px;
  border-radius: 5px;

  &.delivery-badge--self {
    color: #FFF;
    background: #1d70c4;
  }

  &.delivery-badge--dpd {
    color: #FFF;
    background: #b00000;
  }
}

.badge-counter {
  padding: 5px 8px;
  border-radius: 5px;
  font-weight: bold;
  color: #000;
  background: #e5e5e5;
}

.filters-box {
  border-top: 1px solid #fbfbfb;
  border-bottom: 1px solid #fbfbfb;
  background-color: #fbfbfb;
  padding: 10px 10px 0 10px;
  margin: 0 0px;
}

.button-loader {
  margin-right: 10px;
  width: 25px;
  height: 25px;
}

.cursor-pointer {
  &:hover {
    cursor: pointer;
  }
}

.table-listing-holder {
  p {
    margin-bottom: 0;
  }
}
