.error-message {
  color: var(--bs-red);
  position: absolute;
  bottom: -18px;
  left: 0;
  font-size: 12px;
}

.aside-nav-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu-arrow {
  color: #8391a2;

  &:hover {
    cursor: pointer;
  }
}

.navbar-custom.larger {
  left: 60px;
}

.content-page.larger {
  margin-left: 60px;
}

.aside-nav-title,
.navbar-custom,
.logo,
.logo img,
.content-page,
.aside-left {
  transition: all 250ms ease-in-out;
}

.aside-left.smaller {
  .aside-nav-title {
    text-overflow: ellipsis;
    overflow: hidden;
    padding-left: 5px;
    padding-right: 5px;
  }

  .menu-arrow {
    right: 2px;
    top: 15px;
  }

  .logo {
    width: 60px;
    height: 30px;
    margin-top: 20px;

    img {
      width: 100%;
      height: auto;
    }
  }

  .aside-nav-link {
    padding: 1rem 1.2rem;
  }

  .aside-nav-second-level a {
    padding-left: 23px;
  }
}

.aside-nav-item.active {
  background-color: #000;
}

.user-avatar {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: #306ac4;
  color: #fff;
  overflow-y: hidden;

  img {
    width: 100%;
    height: 100%;
  }
}

.hidden {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.ml15 {
  margin-left: 15px;
}

.p5 {
  padding: 25px;
}

.text-left {
  text-align: left;
}
